<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col cols="2" class="mt-5">
        <select v-model="selectedYear" class="form-control">
          <option>2021</option>
          <option>2022</option>
          <option>2023</option>
          <option>2024</option>
        </select>
      </b-col>
      <b-col cols="2" class="mt-5">
        <select v-model="selectedMonth" class="form-control">
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </b-col>
      <b-col class="mt-5" v-if="user.email === 'team10vaghelait@gmail.com'">
        <b-form-checkbox
          id="checkbox-1"
          class="mt-2"
          v-model="includeAll"
          name="checkbox-1"
        >
          Include team10vaghelait@gmail.com events
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :items="monthBreakdown"></b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Main table element -->
        <b-table
          :items="filteredByUser"
          :fields="fields"
          stacked="md"
          show-empty
          small
        >
          <template #[`cell(event)`]="row">
            <template v-if="row.item.event === 'regLookup'">
              Reg Lookup
            </template>
            <template v-else-if="row.item.event === 'addStock'">
              Advert Created
            </template>
            <template v-else-if="row.item.event === 'valuationTool'">
              Valuation Tool
            </template>
            <template v-else>
              {{ row.item.event }}
            </template>
          </template>
          <template #[`cell(timestamp)`]="row">
            {{ formatDate(row.item.timestamp) }}
          </template>
          <template #[`cell(cost)`]="row">
            {{ row.item.cost }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import moment from 'moment'
  import { mapState, mapGetters, mapActions } from 'vuex'

  export default {
    name: 'APIUsage',
    data() {
      return {
        fields: [
          {
            key: 'event',
            label: 'API Call',
            sortable: true,
            class: 'text-center',
          },
          { key: 'timestamp', label: 'Date/Time', sortable: true },
          { key: 'user', label: 'User', sortable: true },
          { key: 'vehicleReg', label: 'VehicleReg', sortable: true },
          { key: 'cost', label: 'Cost', sortable: true },
        ],
        selectedYear: 2021,
        selectedMonth: 4,
        includeAll: false,
      }
    },
    computed: {
      ...mapGetters('admin', ['getAllAPIUsageCalls']),
      ...mapState('user', ['user']),
      filteredByUser() {
        if (this.includeAll === true) {
          return this.filterBySelectedYearMonth
        } else {
          return this.filterBySelectedYearMonth.filter((call) => {
            return call.user === 'sales@midlandautosales.co.uk'
          })
        }
      },
      filterBySelectedYearMonth() {
        if (this.getAllAPIUsageCalls) {
          return this.getAllAPIUsageCalls.filter((call) => {
            return moment(new Date(call.timestamp.seconds * 1000)).isBetween(
              `${this.selectedYear}-${this.selectedMonth}-01`,
              `${this.selectedYear}-${this.selectedMonth}-31`
            )
          })
        } else {
          return []
        }
      },
      monthBreakdown() {
        // count addStock calls
        const regLookupCount = this.filteredByUser.filter((call) => {
          return call.event === 'regLookup'
        }).length

        const addStockCount = this.filteredByUser.filter((call) => {
          return call.event === 'addStock'
        }).length

        const valuationToolCount = this.filteredByUser.filter((call) => {
          return call.event === 'valuationTool'
        }).length

        return [
          {
            api_call: 'Reg Lookup',
            number: `${regLookupCount}`,
            total_cost: `£${(regLookupCount * 0.85).toFixed(2)}`,
          },
          {
            api_call: 'Advert Created',
            number: `${addStockCount}`,
            total_cost: `£${(addStockCount * 0).toFixed(2)}`,
          },
          {
            api_call: 'Valuation Tool',
            number: `${valuationToolCount}`,
            total_cost: `£${(valuationToolCount * 0.25).toFixed(2)}`,
          },
        ]
      },
    },
    mounted() {
      this.dbGetAPIUsage()
    },
    methods: {
      ...mapActions('admin', ['dbGetAPIUsage']),
      formatDate(value) {
        if (value) {
          return moment(new Date(value.seconds * 1000)).format(
            'DD/MM/YYYY HH:mm'
          )
        }
      },
    },
  }
</script>

<style scoped>
  .field-label {
    font-size: 10pt;
  }
</style>
